// fun with iOS 7-style search box in mobile nav
$(document).on('focus', '#mobile-nav-menu .nav-search input', function(){
  var $input = $(this), placeholder = $input.attr('placeholder')
  $input.parents('.nav-search').addClass('focused-input')
}).on('blur', '#mobile-nav-menu .nav-search input', function(){
  if($(this).val() == '') $(this).parents('.nav-search').removeClass('focused-input')
})

// "favorite" button for products
$(document).on('click', '.btn-favorite', function(){
  $(this).toggleClass('active')
})

// mobile menu toggle
$(document).on('click', '.mobile-menu-toggle', function(e){
  var $body = $('body'), $button = $(this)

  if($body.hasClass('mobile-nav-visible')) {
    $body.removeClass('mobile-nav-visible')
  } else {
    $body.addClass('mobile-nav-visible')
  }
})

// shop dropdown toggle
$(document).on('click', '#site-header a[data-toggle="shop-dropdown"]', function(e){
  e.preventDefault()

  $(this).parents('li').addClass('open-dropdown')
  $('#site-header .shop-dropdown').addClass('open')
  $('body').addClass('open-shop-dropdown')
})
// close shop dropdown when clicking outside the dropdown
$(document).on('click', 'body.open-shop-dropdown', function(e){
  if (!$(e.target).closest('.shop-dropdown').length) {
    $('#site-header li.open-dropdown').removeClass('open-dropdown')
    $('#site-header .shop-dropdown').removeClass('open')
    $('body').removeClass('open-shop-dropdown')
  }
})

// Global: hide mobile nav when clicking/tapping
$(document).on('click', '.mobile-nav-visible .nav-slide-element', function(e){
  $('body').removeClass('mobile-nav-visible')
})

// FAQ list toggles on Buy page
$(document).on('click', '.faq-list h3', function(e){
  $(this).parents('.item').toggleClass('open').find('.answer').slideToggle(220)
})

// tooltips
$('.tooltip-toggler').tooltip({
  html: true
})
